import React from "react";

const Educational = () => {
  return (
    <div>
      <h1>EDUCATIONAL RESOURCES</h1>
      <p>
        <a href="https://www.nkdep.nih.gov/">
          National Kidney Disease Education Program (NKDEP)
        </a>
        <br />
        <a href="https://www.aakp.org/">
          American Association of Kidney Patients (AAKP): Very useful website
          for education about dialysis, kidney disease, diet, and other various
          kidney disorders
        </a>
        <br />
        <a href="https://www.nlm.nih.gov/medlineplus/ency/article/002442.htm">
          Chronic Kidney Disease Diet
        </a>
        <br />
        <a href="https://www.kidney.org/">National Kidney Foundation (NKF)</a>
        <br />
        <a href="https://www.kidney.org/transplantation/">
          Kidney Transplantation - Education by NKF
        </a>
        <br />
        <a href="https://www.unos.org/">United Network for Organ Sharing</a>
        <br />
        <a href="https://kidneyfund.org/">American Kidney Fund</a>
        <br />
        <a href="https://www.pkdcure.org/">
          Polycystic Kidney Disease (PKD) Foundation
        </a>
        <br />
        <a href="https://www.pkdcure.org/">
          Kidney Transplantation - Education by NKF
        </a>
      </p>
    </div>
  );
};

export default Educational;
