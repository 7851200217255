import React from "react";
import Huynh from "./Huynh";
import Lone from "./Lone";
import Malas from "./Malas";

const Physicians = () => {
  return (
    <div>
      <Huynh />
      <Lone />
      <Malas />
    </div>
  );
};

export default Physicians;
