import React from "react";

const WinterHaven = () => {
  return (
    <div className="ui grid">
      <div className="two column row">
        <div className="ten wide column">
          <p>
            <strong>
              <u>Winter Haven Office:</u>
            </strong>
            <br />
            1749 6th St NW
            <br />
            Winter Haven, FL 33881
            <br />
            Phone: (863) 226-4676
            <br />
            Fax: (888) 716-2003
            <br />
            Email: akccf@KidneyCareCentralFlorida.com
          </p>
          <p>
            Office Hours:
            <br />
            Monday 1-5pm
            <br />
            <strong>DIRECTIONS:</strong> North part of Spring Lake, near CMX
            movie theater
          </p>
        </div>
        <div className="six wide column">
          <div className="ui embed">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3521.489129735468!2d-81.73911262106563!3d28.040086114094176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88dd13c0ae2a5677%3A0x779cb22f4c029bd8!2sAdvanced%20Kidney%20Care%20of%20Central%20Florida%2C%20PA!5e0!3m2!1sen!2sus!4v1705804688525!5m2!1sen!2sus"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WinterHaven;
