import React from "react";
import About from "./pages/About";
import CKD from "./pages/CKD";
import Physicians from "./pages/Physicians";
import NewPatients from "./pages/NewPatients";
import EstPatients from "./pages/EstPatients";
import Insurances from "./pages/Insurances";
import Forms from "./pages/Forms";
import Educational from "./pages/Educational";
import Billing from "./pages/Billing";
import Contact from "./pages/Contact";
import Email from "./pages/Email";
// TODO: use navigation
// import { BrowserRouter, Route, Link } from "react-router-dom";

//backgroundColor: "#f0f5fa",
const RightPane = ({ page }) => {
  return (
    <div
      style={{
        background: "linear-gradient(#f0f5fa, #c1def4)",
        padding: "40px",
        marginTop: "110px",
        marginBottom: "20px",
      }}
    >
      {page === "about" ? <About /> : null}
      {page === "ckd" ? <CKD /> : null}
      {page === "physicians" ? <Physicians /> : null}
      {page === "newpts" ? <NewPatients /> : null}
      {page === "estpts" ? <EstPatients /> : null}
      {page === "insurances" ? <Insurances /> : null}
      {page === "forms" ? <Forms /> : null}
      {page === "educational" ? <Educational /> : null}
      {page === "billing" ? <Billing /> : null}
      {page === "contact" ? <Contact /> : null}
      {page === "email" ? <Email /> : null}
    </div>
  );
};

export default RightPane;
