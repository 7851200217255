import React from "react";

const WinterGarden = () => {
  return (
    <div className="ui grid">
      <div className="two column row">
        <div className="ten wide column">
          <p>
            <strong>
              <u>Winter Garden Office</u>
            </strong>
            <br />
            1327 Winter Garden Vineland Rd, Ste 130
            <br />
            Winter Garden, Fl 34787
            <br />
            352-240-3812
            <br />
            Fax: (888) 716-2003
            <br />
            Email: akccf@Kidneycarecentralflorida.com
          </p>
          <p>
            <strong>DIRECTIONS:</strong> 1 mile north of Adventhealth Hosp &
            Winter Garden Village Mall
          </p>
        </div>
        <div className="six wide column">
          <div className="ui embed">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.9054712853213!2d-81.58971408509368!3d28.54256088245338!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e7839a25bc9e31%3A0x1206d6a3becb4e1e!2s1327%20Winter%20Garden%20Vineland%20Rd%20Suite%20130%2C%20Winter%20Garden%2C%20FL%2034787!5e0!3m2!1sen!2sus!4v1669698334469!5m2!1sen!2sus"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WinterGarden;
