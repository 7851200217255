import React from "react";
import Clermont from "./Clermont";
import Davenport from "./Davenport";
import WinterHaven from "./WinterHaven";
import WinterGarden from "./WinterGarden";

const Contact = () => {
  return (
    <div>
      <h1>Contact</h1>
      <Clermont />
      <p>
        <br />
      </p>
      <div className="ui divider"></div>
      <p>
        <br />
      </p>
      <Davenport />
      <p>
        <br />
      </p>
      <div className="ui divider"></div>
      <p>
        <br />
      </p>
      <WinterHaven />

      <p>
        <br />
      </p>
      <div className="ui divider"></div>
      <p>
        <br />
      </p>
      <WinterGarden />
    </div>
  );
};

export default Contact;
