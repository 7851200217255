import React from "react";

const ListItem = ({ label, children, page, onPageChange }) => {
  return (
    <div
      className="item"
      onClick={(event) => {
        onPageChange(page, event);
      }}
      style={{ color: "white", cursor: "pointer" }}
    >
      <i className="caret right icon"></i>
      <div className="content">{label}</div>
      {children}
    </div>
  );
};

export default ListItem;
