import React from "react";
import ListItem from "./ListItem";
// TODO: use navigation
// import { BrowserRouter, Route, Link } from "react-router-dom";

const LeftPane = ({ onPageChange }) => {
  return (
    <div
      className="ui list"
      style={{
        marginLeft: "15px",
        backgroundColor: "#184d97",
      }}
    >
      <ListItem label="About us" page="about" onPageChange={onPageChange} />
      <ListItem
        label="Chronic Kidney Disease"
        page="ckd"
        onPageChange={onPageChange}
      />

      <ListItem
        label="Physicians"
        page="physicians"
        onPageChange={onPageChange}
      />
      <ListItem label="Patients" page="newpts" onPageChange={onPageChange}>
        <div className="list">
          <ListItem
            label="New Patients"
            page="newpts"
            onPageChange={onPageChange}
          />
          <ListItem
            label="Established Patients"
            page="estpts"
            onPageChange={onPageChange}
          />
          <ListItem
            label="Participating Insurances"
            page="insurances"
            onPageChange={onPageChange}
          />
        </div>
      </ListItem>
      <ListItem label="Forms" page="forms" onPageChange={onPageChange} />
      <ListItem
        label="Educational Resources"
        page="educational"
        onPageChange={onPageChange}
      />
      <ListItem label="Billing" page="billing" onPageChange={onPageChange} />
      <ListItem label="Contact Us" page="contact" onPageChange={onPageChange} />
    </div>
  );
};

export default LeftPane;
