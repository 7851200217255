import React from "react";
import PicMD from "../../components/PicMD";

const Huynh = () => {
  return (
    <div>
      <PicMD doctor="Huynh" />
      <strong>Meet Dr. Bao Huynh</strong>
      <br />
      <br />
      <p>
        Dr. Bao Huynh founded AKCCF in 2010. Growing up in Minnesota, Dr. Huynh
        started his college education at the University of Minnesota - College
        of Liberal Arts, Honor's Division. Coming from a family of pharmacists,
        it was natural that he followed in the footsteps of his father and older
        sister, earning a Bachelor of Science and later a Doctor of Pharmacy at
        the University of Minnesota, where he was elected to the prestigious Rho
        Chi pharmacy honor society and graduated at the top of his class with
        the highest honor, Summa Cum Laude.
      </p>
      <p>
        Dr. Huynh practiced as a clinical pharmacist at Methodist Hospital for a
        year before returning back to medical school. He received his Medical
        Doctor from the University of North Dakota, graduating with the
        prestigious Alpha Omega Alpha medical honor. He was selected into a
        highly competitive postgraduate training program in Internal Medicine at
        the eminent world-renowned Washington University in St Louis, where he
        carried out clinical research in heart failure and published in
        peer-reviewed medical journals. His appeal for physiology and the
        management of complex patients led him pursue a nephrology fellowship at
        the highly respected University of Florida, where he was selected to
        serve as Chief Fellow.
      </p>
      <p>
        With his interest in academics, Dr. Huynh serves a mentor, preceptor,
        and clinical role model for medical students as an affiliated/volunteer
        faculty Assistant Professor of Internal Medicine at the University of
        Central Florida College of Medicine. He is also chairman of the Pharmacy
        & Therapeutics Committee at Heart of Florida Hospital. At South Lake
        Hospital, he serves on the Critical Care Committee.
      </p>
      <br />
    </div>
  );
};

export default Huynh;
