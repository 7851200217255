import React, { useState } from "react";
import Header from "./components/Header";
import LeftPane from "./components/LeftPane";
import RightPane from "./components/RightPane";
import Footer from "./components/Footer";
import styled from "styled-components";
import backgroundImage from "./images/header_04.jpg";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 0.29fr 1fr 0.03fr;
  background-color: #184d97;
  background-image: url(${backgroundImage});
  background-size: contain;
  background-repeat: no-repeat;
  max-width: 962px;
`;

const App = () => {
  const [activePage, setActivePage] = useState("about");

  const onPageChange = (page, event) => {
    event.stopPropagation(); // stops event propagation to parent DOM element
    if (!page) return;
    setActivePage(page);
  };

  return (
    <div className="ui grid container">
      <div>
        <Header />
        <Grid>
          <LeftPane onPageChange={onPageChange} />
          <RightPane page={activePage} />
        </Grid>

        <br />
        <div className="ui divider"></div>

        <Footer />
      </div>
    </div>
  );
};

export default App;
