import React from "react";

const Footer = () => {
  return (
    <div className="ui small label" style={{ backgroundColor: "transparent" }}>
      © Copyright 2022 Advanced Kidney Care of Central Florida. All rights
      reserved.
      <br />
      <br />
    </div>
  );
};

export default Footer;
