import React from "react";

const Insurances = () => {
  return (
    <div>
      <h1>Patients</h1>
      <strong>PARTICIPATING INSURANCES</strong>
      <br />
      <br />
      <p>
        AARP Medicare Complete <br />
        Aetna <br />
        AmBetter
        <br />
        Amerigroup <br />
        AvMed <br />
        Baycare <br />
        Blue Cross Blue Shield (Florida Blue) <br />
        Care Plus
        <br /> Cigna <br />
        Coventry <br />
        Devoted <br />
        Evercare <br />
        Florida True Health <br />
        Freedom Health <br />
        Freedom First
        <br /> HealthChoice
        <br /> Healthease
        <br />
        Humana Medicaid (Florida) Medicare <br />
        Imagine <br />
        Optimum <br />
        Oscar <br />
        Preferred Care Partners <br />
        Railroad Medicare <br />
        Secure Horizons <br /> Simply Healthcare <br />
        Staywell <br />
        Sunshine State Health <br />
        Tricare Tricare Prime UMR <br />
        United Health Care <br />
        Wellcare
      </p>
    </div>
  );
};

export default Insurances;
