import React from "react";

const Clermont = () => {
  return (
    <div className="ui grid">
      <div className="ui two column row">
        <div className="ten wide column">
          <strong>
            <u>Clermont Office:</u>
          </strong>
          <p>
            3175 Citrus Tower Blvd. <br />
            Clermont, FL 34711
            <br /> Phone: (352) 240-3812 <br />
            Fax: (888) 716-2003 <br />
            Email: akccf@kidneycarecentralflorida.com
          </p>
          <p>
            Office Hours: <br />
            Monday – Friday 8-5 <br />
            <strong>DIRECTIONS</strong>: 0.4 miles south of John’s Lake
            Wal-Mart, inside Lost Lake Professional Plaza
          </p>
        </div>
        <div className="six wide column">
          <div className="ui embed">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.57881681308!2d-81.73050458509415!3d28.522317082461445!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e78f2ec2d7b317%3A0x318288c3ebc9d4c5!2sAdvanced%20Kidney%20Care%20of%20Central%20Florida%2C%20PA!5e0!3m2!1sen!2sus!4v1669701796801!5m2!1sen!2sus"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clermont;
