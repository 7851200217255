import React from "react";
import newForm from "../../content/Forms-New-Patient-Packet.pdf";
const Forms = () => {
  return (
    <div>
      <h1>FORMS</h1>
      <p>
        You can start preparing for your visit now! If you would like to save
        time, you can print these forms now, complete them as you have time, and
        bring the completed forms with you to your visit. You will save time by
        not having to complete these forms in our waiting room.
      </p>
      <p>
        If you don't have the Acrobat Reader, you can click{" "}
        <a href="https://get.adobe.com/reader/">here</a> for a free download.
      </p>
      <p>
        FORMS FOR NEW PATIENTS TO PRINT, COMPLETE, AND BRING TO THEIR 1ST VISIT:
      </p>
      <p>
        <a href={newForm}>New Patient Packet</a>
      </p>
    </div>
  );
};

export default Forms;
