import React from "react";
import image1 from "../images/header_01.jpg";
import image2 from "../images/header_02.jpg";
import image3 from "../images/header_03.jpg";

const Header = () => {
  return (
    <div>
      <img src={image1} className="ui image"></img>
      <img src={image2} className="ui image"></img>
      <img src={image3} className="ui image"></img>
    </div>
  );
};

export default Header;
