import React from "react";

const NewPatients = () => {
  return (
    <div>
      <h1>Patients</h1>
      <strong>NEW PATIENTS</strong>
      <p>
        New patients may download new patient forms off the web in the "Forms"
        section. Fill out the New Patient Packet and bring them to your
        appointment to expedite the process.
      </p>
      <p>
        We look forward to the opportunity to serve your health care needs. Our
        goal is to treat you in a respectful and responsive manner. Our
        physicians have excellent credentials, and we seek to care for all of
        our patients in a compassionate, friendly atmosphere.
      </p>
      <p>
        If you have an HMO type of insurance, you will probably need to obtain a
        referral to our specialist office. If you have lab tests performed in
        the weeks preceding your appointment with us, please try to bring a copy
        of the results with you.
      </p>
      <p>
        We normally take care of registration and co-payments as patients
        check-in so we can focus the end of the visit on clinical issues. We
        accept checks, cash, and most credit cards.
      </p>
      <p>See our list of Accepted Insurance Plans.</p>
      <p>
        Once we have scheduled an appointment time for you, please arrive 15
        minutes prior to this time for paperwork.
      </p>

      <p>
        Please bring the following with you to your appointment: <br />
        &emsp; 1. Completed patient information forms <br />
        &emsp; 2. Driver's License or Photo ID <br />
        &emsp; 3. Current medications (bring bottles) <br />
        &emsp; 4. Any medical records, laboratory results
      </p>

      <p>
        Thank you for considering our office for your Nephrology care and we
        look forward to seeing you.
      </p>
    </div>
  );
};

export default NewPatients;
