import React from "react";
import HuynhPic from "../images/BaoHuynhPortrait.jpg";
import LonePic from "../images/UsmanLonePortrait.jpg";
import MalasPic from "../images/MALAS.png";

let srcLink;

const PicMD = ({ doctor }) => {
  if (doctor === "Huynh") srcLink = HuynhPic;
  if (doctor === "Malas") srcLink = MalasPic;
  if (doctor === "Lone") srcLink = LonePic;

  return (
    <div>
      <img
        src={srcLink}
        style={{
          float: "left",
          width: "106px",
          height: "122px",
          aspectRatio: "106 / 122",
          marginRight: "15px",
        }}
        className="ui image"
      />
    </div>
  );
};

export default PicMD;
