import React from "react";
import PicMD from "../../components/PicMD";

const Lone = () => {
  return (
    <div>
      <PicMD doctor="Lone" />
      <strong>Meet Dr. Usman Lone</strong>
      <br />
      <br />
      <p>
        Dr. Usman Lone joined AKCCF in 2013. Dr. Lone and his family grew up in
        Pittsburgh, PA. Taking advantage of the land of opportunities, Dr. Lone
        earned a B.S. in Neuroscience from the University of Pittsburgh. He then
        went on to earn his MD from Drexel University College of Medicine in
        Philadelphia, PA, where he stayed and completed his postgraduate
        internship and residency training in Internal Medicine. Being intrigued
        and fascinated by the human physiology, Dr. Lone chose to specialize in
        nephrology and continued further training at the prestigious Ivy League
        Brown University School of Medicine in Providence, RI.
      </p>
      <p>
        Dr. Lone demonstrates the perfect balance between clinical excellence
        and compassionate, humanistic care. He joined the Pittsburgh Health
        Corps, a program of the National Health Corps AmeriCorps program, where
        he was committed to decreasing the disparities in local healthcare
        delivery systems and collaborated with a broad range of sites to ensure
        members are exposed to diverse opportunities in public health. Sites
        typically include homeless clinics, schools, health centers, and
        inter-generational community programs.
      </p>
      <p>
        Dr. Lone is a designated Specialist in Clinical Hypertension by the
        American Society of Hypertension, a designation to recognize physicians
        with expert skills and knowledge in the management of clinical
        hypertension and related disorders.
      </p>
      <br />
    </div>
  );
};

export default Lone;
