import React from "react";

const Davenport = () => {
  return (
    <div className="ui grid">
      <div className="two row column">
        <div className="ten wide column">
          <p>
            <strong>
              <u>Davenport Office:</u>
            </strong>
            <br />
            2400 North Blvd, Suite A<br />
            Davenport, FL 33837
            <br />
            Phone: (863) 226-4676
            <br />
            Fax: (888) 716-2003
            <br />
            Email: akccf@kidneycarecentralflorida.com
          </p>
          <p>
            Office Hours:
            <br />
            Wednesday 8-5 and Thursday 8-5
            <br />
            <strong>DIRECTIONS:</strong> Off HWY 27, 1 mile south of Heart of
            Florida Medical Center, Inside florida Professional Center
          </p>
          <p>
            <strong>Request an Appointment:</strong> You may call our Clermont
            or Davenport office Monday – Friday 8-5 to make an appointment.
          </p>
        </div>
        <div className="six wide column">
          <div className="ui embed">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3517.3537666990787!2d-81.64029086657851!3d28.166154759025343!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88dd7170494281ad%3A0x2164bcc1f8d72aab!2sAdvanced%20Kidney%20Care%20of%20Central%20Florida%2C%20PA!5e0!3m2!1sen!2sus!4v1669697883948!5m2!1sen!2sus"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Davenport;
