import React from "react";

const CKD = () => {
  return (
    <div>
      <h1>CHRONIC KIDNEY DISEASE</h1>
      <span>
        <strong>What is Chronic Kidney Disease (CKD)?</strong>
      </span>
      <div className="ui bulleted list">
        <div className="ui item">
          CKD is a reduction in glomerular filtration rate (GFR or "kidney
          function") &#60; 60ml/min OR structural damage to the kidneys
          (resulting in blood or protein spillage into the urine).
        </div>
        <div className="ui item">
          As CKD progresses, complications like high blood pressure, anemia (low
          blood count), weak bones, poor nutritional health, and nerve damage
          develop. With deterioration of "kidney function", metabolic wastes
          build up in the blood and make you feel sick.
        </div>
        <div className="ui item">
          Risk factors for developing CKD: Diabetes mellitus, Hypertension,
          &#62;60 years old, Racial/Ethnic minorities (African Americans,
          Hispanics), Family history of kidney disease, Kidney stones, History
          of tobacco use.
        </div>
        <div className="ui item">
          How to test for CKD: Blood creatinine level, Urine test for
          Albumin/Protein, Blood.
        </div>
      </div>
      <br />
      GFR Calculator: Link http://mdrd.com
      <br />
      <br />
      <span>
        <strong>CKD Facts</strong>
      </span>
      <div className="ui bulleted list">
        <div className="ui item">
          In 1999-2004, an estimated 26 million adults (13%) in United States
          had CKD. Now, the prevalence has increased to 1 in 8 adults in the
          United States having CKD.
        </div>
        <div className="ui item">
          Nearly 50% of individuals 70 years or older meet the definition of
          CKD.
        </div>
        <div className="ui item">
          Millions of Americans have CKD and not know it because many are
          walking around with undiagnosed Diabetes and Hypertension or never get
          tested for CKD. In CKD stage 3, only 11.6% of men and 5.5% of women
          reported being aware of having "weak or failing" kidneys.
        </div>
        <div className="ui item">
          Diabetes mellitus and Hypertension are the 2 leading causes of CKD in
          the US.
        </div>
        <div className="ui item">
          CKD is associated with greater risk of death and of cardiovascular
          disease (High blood pressure, Heart attack, Stroke, Heart failure).
        </div>
        <div className="ui item">
          Early detection can help prevent progression of kidney disease to
          kidney failure and reduce mortality from CKD-related complications.
        </div>
      </div>
      <br />
      <span>
        <strong>What are the Signs and Symptoms of Kidney Disease?</strong>
      </span>
      <p>
        The signs/symptoms of kidney disease are very subtle and most people
        will not feel them until they are close to the dialysis stage. Knowing
        the early signs/symptoms of kidney disease can help you seek treatment
        before it rapidly progresses to the dialysis stage. The following
        signs/symptoms may suggest kidney disease:
      </p>
      <div className="ui bulleted list">
        <div className="ui item">
          Changes in urination: Decrease amount, Frequent urination,
          Pressure/Difficulty urinating
        </div>
        <div className="ui item">
          Fatigue, Sleepiness, Trouble concentrating (from build up of waste
          products/toxins)
        </div>
        <div className="ui item">Swelling in ankles, feet, hands, face</div>
        <div className="ui item">
          Nausea/Vomiting, Loss of appetite, Abnormal taste to foods (Metallic
          taste)
        </div>
        <div className="ui item">
          Pain in the back/sides (enlarged kidneys, cysts)
        </div>
        <div className="ui item">
          Shortness of Breath/Heart failure from fluid retention
        </div>
        <div className="ui item">
          Blood in urine; Protein in urine (foamy/bubbly urine); Very pale or
          very dark urine
        </div>
        <div className="ui item">Anemia (Feeling cold, Fatigue)</div>
        <div className="ui item">
          Skin rash/Itching (from buildup of waste products)
        </div>
      </div>
    </div>
  );
};

export default CKD;
