import React from "react";
import welcome from "../../images/welcome.gif";
import office from "../../images/office2.png";

const About = () => {
  return (
    <div>
      <img src={welcome} className="ui image" />
      <h1>ABOUT US</h1>

      <p>
        <strong>Advanced Kidney Care of Central FL </strong>
        is a comprehensive medical practice specializing in the diagnosis,
        preventive therapy, and management of kidney-related diseases,
        hypertension, kidney stones, dialysis, and kidney transplant care.
      </p>
      <p>
        Our practice is committed to providing the most advanced, comprehensive,
        and compassionate care for kidney disease patients in Central Florida.
      </p>
      <p>
        <strong>What is a nephrologist (kidney doctor)?</strong>
        <br />A nephrologist is a medical doctor who is board certified in
        Internal Medicine and then undergo additional training to specialize in
        the diagnosis and treatment of kidney diseases, electrolyte disorders,
        and hypertension, and provide care for people requiring renal
        replacement therapy, including dialysis and kidney transplantation.
      </p>
      <br />
      <img src={office} className="ui image" />
    </div>
  );
};

export default About;
