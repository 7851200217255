import React from "react";

const EstPatients = () => {
  return (
    <div>
      <h1>Patients</h1>
      <strong>ESTABLISHED PATIENTS</strong>
      <br />
      <p>
        Patient instructions
        <div className="ui bulleted list">
          <div className="ui item">
            Your co-pay is due at the time of service. Please note that we do
            accept Visa, MasterCard, American Express, Discover, debit cards,
            checks, and cash.
          </div>
          <div className="ui item">
            If your insurance company requires a referral or authorization, be
            sure to have this ahead of time and bring it with you. Your
            appointment will need to be rescheduled if authorization is not
            obtained.
          </div>
          <div className="ui item">
            It is your responsibility to contact the office with insurance
            changes including the effective dates.
          </div>
          <div className="ui item">
            Lab work is very important to determine if your kidney disease is
            stable or progressing. Please have your labs drawn 1-2 weeks before
            appointment.
          </div>
          <div className="ui item">
            Bring the following with you to EVERY appointments: <br />
            1. Current medications (bring bottles) <br />
            2. Insurance ID card. The receptionist will need to update insurance
            information at every visit. <br />
            3. Log of blood pressure and blood sugar readings (if any)
            <br /> 4. Updates of any new prescriptions, hospitalizations,
            procedures/surgeries, tests
          </div>
          <div className="ui item">
            If our nurse is unavailable when you call, please leave a voice mail
            message and they will return your call the same business day.
          </div>
          <div className="ui item">
            In order for Advance Kidney Care of CF to provide you with the best
            care possible, we ask that you make every effort to keep your
            scheduled appointments and arrive in a timely manner.
          </div>
          <div className="ui item">
            Cancellation/Rescheduling policy: In order to be respectful of the
            medical needs of the Central FL community and valuable staff time,
            we ask that you call 24 hours before your scheduled appointment to
            cancel/reschedule. This will enable us to better utilize available
            appointments for our patients in need of medical care.
          </div>
        </div>
      </p>
      <p>
        <strong>Prescription Refill policy</strong>
      </p>
      <p>
        For all prescription refills, first call your pharmacy and ask them to
        FAX the refill request to our office (888-716-2003). It is our office
        policy to refill medications during office hours only. We are able to
        respond to faxes from 8am-4pm M-F. Any request faxed after these hours,
        on the weekends, or on holidays may not be filled until the next
        BUSINESS day. Please allow 24 hours processing time for refills. Please
        ensure that any prescriptions you have do not run out on weekends or
        holidays.
      </p>
      <p>
        <strong>
          For pharmacies that DO NOT participate in fax Requests, you may
          call/Email us your refill requests. Please provide us with the
          following infomation:
        </strong>
      </p>
      <p>
        Patient name, Date of Birth, Phone Number
        <br /> Pharmacy Name, Pharmacy Phone Number <br />
        Medication Name, Dosage/strength, Quantity, Prescription Number
        <br />
        Please allow 24-48 hours for completion of your requests.
      </p>
    </div>
  );
};

export default EstPatients;
