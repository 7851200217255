import React, { useState } from "react";

const Billing = () => {
  const [amount, setAmount] = useState("");
  const [buttonMsg, setButtonMsg] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const onInputChange = (event) => {
    setAmount(event.target.value);
    setButtonMsg("");
  };

  const onFormSubmit = (event) => {
    if (!amount) {
      event.preventDefault();
      setButtonMsg("Please enter payment amount");
      return;
    }
    setButtonDisabled(true);
  };

  return (
    <div>
      <h1>Billing</h1>
      <h3>Online Bill Pay:</h3>
      <form
        onSubmit={onFormSubmit}
        className="ui form"
        method="post"
        action="https://www.payjunction.com/trinity/quickshop/add_to_cart_snap.action"
      >
        <label>Enter Payment Amount:</label>
        <div className="ui row">
          <div className="ui input">
            <input type="hidden" name="store" value="gx8afuq" />
            <input type="hidden" name="need_to_ship" value="no" />
            <input type="hidden" name="need_to_tax" value="no" />
            <input type="hidden" name="identifier" value="Invoice Payment" />
            <input
              type="hidden"
              name="description"
              value="Thank you for making a payment to Advanced Kidney Care of Central Florida!"
            />
            <input type="hidden" name="quantity" value="1" />
            <input
              type="text"
              value={amount}
              onInput={onInputChange}
              placeholder="Amount"
              name="price"
            ></input>
          </div>
          <button
            className={`ui primary button ${buttonDisabled ? "disabled" : ""}`}
            style={{ marginLeft: "15px" }}
          >
            Check Out
          </button>
        </div>
        {buttonMsg ? (
          <div className="ui pointing red basic label">{buttonMsg}</div>
        ) : null}
      </form>

      <h3>By Mail:</h3>
      <p>
        Submit your check along with your billing statement to: <br />
        Advanced Kidney Care of Central Florida <br />
        3175 Citrus Tower Blvd.
        <br /> Clermont, FL 34711
      </p>
      <h3>In Person:</h3>
      <p>
        Come to the Clermont office with your billing statement. We accept cash,
        checks, and credit cards (Visa, Master Card, Discover, and American
        Express).
      </p>
      <h3>By Phone (via credit card):</h3>
      <p>Please call (352) 240-3812 and have your billing statement ready.</p>
      <h3>Billing Team Contact Info:</h3>
      <p>
        Please address any of your billing concerns or questions to our billing
        team. They are readily available to assist you. Please dial (352)
        240-3812 and allow 24-48 hours for a return phone call.
      </p>
    </div>
  );
};

export default Billing;
