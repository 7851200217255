import React from "react";
import PicMD from "../../components/PicMD";

const Malas = () => {
  return (
    <div>
      <PicMD doctor="Malas" />
      <strong>Meet Dr. Saeed Malas</strong>
      <br />
      <br />
      <p>
        Dr. M Saeed Malas joined AKCCF in 2017. He earned his Medical Degree
        from Damascus University in Syria. He then moved to the United States
        where he went to Indiana University in Indiana for his Internal medicine
        residency. Dr. Malas is very interested in the kidney physiology so he
        extended his medical training by specializing in Nephrology.
        Furthermore, he completed an additional fellowship in clinical
        informatics and earned a Masters Degree in clinical research.
      </p>
      <p>
        Dr. Malas worked with multidisciplinary teams to explore a number of
        administrative aspects of the national End Stage Renal Disease program.
        He received private funding, collaborated with leading nephrologists and
        informaticians and published his research work in peer-reviewed
        journals.
      </p>
      <p>
        Dr. Malas is passionate about preventing kidney disease and slowing the
        progression of Chronic Kidney Disease in the community. Moreover, Dr.
        Malas is motivated to advance comprehensive kidney stones prevention
        strategies and promote Home Dialysis Therapy.
      </p>
    </div>
  );
};

export default Malas;
